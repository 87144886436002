import CategoryVideoGames from '../assets/category_header_icons/gamepad.svg';
import CategoryHighTech from '../assets/category_header_icons/computer.svg';
import CategoryGrocery from '../assets/category_header_icons/grocery-cart.svg';
import CategoryFashion from '../assets/category_header_icons/dress.svg';
import CategoryCosmetics from '../assets/category_header_icons/cosmetics.svg';
import CategoryFamily from '../assets/category_header_icons/toys.svg';
import CategoryHome from '../assets/category_header_icons/furniture.svg';
import CategoryAutomotive from '../assets/category_header_icons/motorcycle.svg';
import CategoryEntertainment from '../assets/category_header_icons/movie.svg';
import CategorySports from '../assets/category_header_icons/sports.svg';
import CategoryFinance from '../assets/category_header_icons/salary.svg';
import CategoryTravel from '../assets/category_header_icons/travel-luggage.svg';
import CategoryBuilding from '../assets/category_header_icons/settings.svg';

export const CATEGORIES = [
    { id: 1, name: "Consoles & Video Games", icon: CategoryVideoGames },
    { id: 2, name: "High-Tech", icon: CategoryHighTech },
    { id: 3, name: "Grocery & Shopping", icon: CategoryGrocery },
    { id: 4, name: "Fashion", icon: CategoryFashion },
    { id: 5, name: "Health & Cosmetics", icon: CategoryCosmetics },
    { id: 6, name: "Family & Children", icon: CategoryFamily },
    { id: 7, name: "Home & DIY", icon: CategoryHome },
    { id: 8, name: "Automotive & Motorcycle", icon: CategoryAutomotive },
    { id: 9, name: "Culture & Entertainment", icon: CategoryEntertainment },
    { id: 10, name: "Sports & Outdoors", icon: CategorySports },
    { id: 11, name: "Finance & Insurance", icon: CategoryFinance },
    { id: 12, name: "Travel", icon: CategoryTravel },
    { id: 13, name: "Garden & DIY (Do-It-Yourself)", icon: CategoryBuilding },
];
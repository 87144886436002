// NotFoundPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/'); // Redirect to homepage
        }, 3000); // 5000 milliseconds = 5 seconds

        return () => clearTimeout(timer); // Clear the timer if the component unmounts
    }, [navigate]);

    return (
        <div>
            <h1>404 - Page Not Found</h1>
            <p>Redirecting to the homepage in 3 seconds...</p>
        </div>
    );
};

export default NotFoundPage;
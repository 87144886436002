// LoadingSpinner.js

import React from 'react';
import './LoadingSpinner.css'; // Assume you have some CSS for the spinner

const LoadingSpinner = () => {
    return (
        <div className="loading-spinner__overlay">
            <div className="loading-spinner__spinner"></div>
        </div>
    );
};

export default LoadingSpinner;
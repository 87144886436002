// ShareDeal.js

import { FRONT_URL } from '../services/api';
import { createSlug } from '../utils/utils';

export const shareDeal = async (dealTitle, dealId, alertPopup) => {
    const formattedTitle = createSlug(dealTitle);
    const dealUrl = `${FRONT_URL}deal/${formattedTitle}/${dealId}`;

    const shareData = {
        title: `Check out this deal: ${dealTitle}`,
        text: `I found this amazing deal: ${dealTitle}! Check it out!`,
        url: dealUrl,
    };

    try {
        if (navigator.share) {
            await navigator.share(shareData);
        } else {
            const fullMessage = `${shareData.title}\n${shareData.text}\n${shareData.url}`;
            await navigator.clipboard.writeText(fullMessage);
            alertPopup('Link copied to clipboard!', 'success');
        }
    } catch (error) {
        if (error.name === 'AbortError' || error.message === 'Share canceled') {
            // User canceled the share action, handle it silently
            console.log('Share canceled by user.');
        } else {
            alertPopup('Error sharing: ' + error.message, 'Error');
        }
    }
};
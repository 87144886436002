import React from 'react';
import Container from '../components/Container'; // Adjust the import path as needed
import './TermsAndConditions.css';

const TermsAndConditions_eng = () => {
    return (
        <Container>
            <div className="terms-and-conditions">
                <h1>TERMS AND CONDITIONS FOR USE</h1>
                <div className="sub-heading">Terms and Conditions for Use of Dealio</div>
                <h2>1. Definitions and Purpose</h2>
                <p>Dealio is an online platform providing a community space for sharing various promotional offers. Through its site, Dealio provides users with a temporary and revocable platform where members can:</p>
                <ul>
                    <li>Create and manage their personal profiles, view their offers on the site, and view offers from other members.</li>
                    <li>Post offers.</li>
                    <li>Vote for or against offers.</li>
                    <li>Engage in discussions with other members by posting comments.</li>
                    <li>Receive all communications from Dealio intended for members, such as administrative messages, which the member expressly agrees to receive.</li>
                </ul>
                <p>These functions are provided as-is and are not limited to the aforementioned list. Dealio reserves the right to modify or delete any of these functions at any time, without prior notice or liability. Access to the Dealio website and the use of its content by users are subject to these Terms and Conditions of Use.</p>
                <p className="important-notice"><strong>IMPORTANT NOTICE FOR USERS</strong></p>
                <p className="uppercase">USING THE DEALIO WEBSITE OR ANY FEATURE OF THE DEALIO WEBSITE IMPLIES PRIOR, FULL, AND COMPLETE ACCEPTANCE BY USERS OF THESE TERMS AND CONDITIONS OF USE. BY CREATING A PROFILE, USERS ACKNOWLEDGE THAT THEY ACCEPT THESE TERMS AND CONDITIONS OF USE BY CLICKING ON THE "SIGN UP" BUTTON. THESE TERMS AND CONDITIONS ARE REGULARLY UPDATED, AND USERS ARE INVITED TO REVIEW THEM AT EACH OF THEIR VISITS.</p>
                <h3>1.1. DEFINITIONS</h3>
                <p>The definitions below in the singular also apply to the plural, and vice versa, depending on the context.</p>
                <ul>
                    <li><strong>"Terms and Conditions"</strong> refers to these terms of use applicable to all users of the site.</li>
                    <li><strong>"Content"</strong> refers to any visual or textual element published on the site by the member and associated with their profile.</li>
                    <li><strong>"Dealio"</strong> refers to the company operating the platform www.dealio.asia, contactable via dealio.vn@gmail.com.</li>
                    <li><strong>"Merchant"</strong> refers to professional online or physical retailers whose commercial offers can be published by members as offers.</li>
                    <li><strong>"User"</strong> refers to any user registered on the site as per section 4 of these Terms and Conditions.</li>
                    <li><strong>"Offer" or "Deal"</strong> refers to a promotional offer consisting of a hyperlink redirecting to an online or physical store of a third-party commercial company.</li>
                    <li><strong>"Profile"</strong> refers to the personal page of members on the site, accessible only after the member has logged in via their email and password or via social media. It is from their profile that the member can enrich the information they wish to share with other members, access their settings, and manage their space.</li>
                    <li><strong>"Services"</strong> refers to the various features offered by the site to users, such as voting for offers or participating in discussions.</li>
                    <li><strong>"Site"</strong> refers to the Dealio website, with the main address www.dealio.asia. The site includes all IT components (including software and developments, web pages, source and object code, domain names, preparatory design work, specifications, preliminary studies, user documentation, database), graphic design (including projects, models, prototypes, plans), content (including textual, graphic, sound, photographic, multimedia elements), and functionalities, as well as all related rights.</li>
                    <li><strong>"User"</strong> refers to any internet user, whether registered or not on the site, as per section 4 of these Terms and Conditions.</li>
                </ul>
                <h3>1.2. PURPOSE</h3>
                <p>These Terms and Conditions govern the conditions and modalities of use by users and members of the site's services, as well as define the rights and obligations of users and members who are connected via the site.</p>

                <h2>2. Accessibility and Profile</h2>
                <h3>2.1. ACCESS TO THE SITE AND PROFILE</h3>
                <p>The site is accessible via the URL: www.dealio.asia</p>
                <p>Access to profiles is open to all registered internet users as per section 4: Member Registration and Profile Creation. Once registered, members can log in to their profiles via a social media identification method.</p>
                <p>The services of the site may evolve with updates to meet new technical, commercial, or legal requirements. Consequently, Dealio cannot be held responsible for such evolution, including the removal of any existing services at the time of registration on the site.</p>
                <h3>2.2. MEMBER REGISTRATION AND PROFILE CREATION</h3>
                <p>Registration on the profile is done via the site. It is free, except for connection fees, which are the sole responsibility of users. It implies full, complete, and unreserved acceptance of these Terms and Conditions.</p>
                <p>Any person aged thirteen (13) years or older can become a member. Dealio does not intervene in the creation of profiles and does not verify the accuracy, reliability, or timeliness of the information provided by members. Consequently, Dealio cannot be held responsible for the information contained in profiles.</p>
                <p>Members are expected to adhere to high standards of courtesy, decency, and mutual respect. Members agree not to use pseudonyms or avatars that may offend others, are contrary to public order or morals, or infringe on the rights of third parties, or that are promotional in nature. Dealio reserves the right to moderate pseudonyms and avatars used by members and may refuse a registration. A person cannot have multiple profiles on the site.</p>
                <h3>2.3. SITE ACCESSIBILITY</h3>
                <p>To access the site, users must:</p>
                <ul>
                    <li>Accept cookies and enable JavaScript.</li>
                    <li>Be of legal age or have prior authorization from their legal representative.</li>
                </ul>
                <p>Access to the site can be made from a computer, smartphone, or tablet connected to www.dealio.asia by any means of telecommunication. Dealio does not provide users with any equipment, telephone installation, terminal equipment, consumables, or other materials. Whatever the mode of connection chosen, connection fees are the responsibility of users, as well as any amount, fee, registration, tax, or other charges that may be requested by access providers to telecommunication networks connecting users to the site.</p>
                <p>Dealio makes its best efforts to ensure the availability of the site. However, users are informed that the site is not subject to any obligation of availability, accessibility, or performance. Therefore, users cannot claim any defect that prevents the use of the site or degrades the user experience.</p>
                <p>The site is online 24/7, but Dealio reserves the right to modify or temporarily or permanently interrupt access to the site for maintenance, updates, improvements, or to evolve its content and/or presentation without notice or compensation. Dealio will inform members in advance of such operations whenever possible.</p>
                <p>Dealio reserves the right to suspend without notice multiple accounts of the same member or account of members not respecting these Terms and Conditions, including but not restricted to: posting fake or misleading content, engaging in abusive or inappropriate behavior, using the site for illegal activities, impersonating another member, self-promotion, circumventing sanctions, spamming, harming the site or its users, infringing on intellectual property rights, and posting defamatory, insulting, racist, xenophobic, or pornographic content.</p>

                <h2>3. Intellectual Property Rights</h2>
                <h3>3.1. INTELLECTUAL PROPERTY RIGHTS</h3>
                <h4>3.1.1. Intellectual Property Rights of Users</h4>
                <p>Users declare and guarantee that they are the sole owners of all intellectual property rights and/or image rights on the information (texts, images, drawings, photographs, comments, trademarks, social names, etc.) and/or comments published or distributed on the site. If not, they must have all the necessary rights, authorizations, and assignments for the publication and display of said information and the granting of rights to Dealio as described below. Members agree to check the terms of use and reproduction of the content of the merchant's site from which the commercial offer originates before publishing any offer.</p>
                <p>They also guarantee that the information and/or comments and/or offers published or distributed do not constitute, in whole or in part, acts of counterfeiting, unfair competition, commercial parasitism, or infringement of the image rights of persons or property. Users retain all possible intellectual property rights on the information (texts, images, drawings, photographs, comments, trademarks, social names, etc.) they distribute and/or publish on their profile.</p>
                <p>Users grant Dealio a license to use the content they publish on the site, except for private messages, for the entire duration of copyright protection in Vietnam, worldwide, free of charge, irrevocable, non-exclusive, and transferable. This usage license includes the right for Dealio to use, reproduce, represent, adapt, translate, or sublicense the content published by members, except for private messages, in whole or in part, in all formats, by any means, on the site or on all advertising and promotional media directly related to the site, without limitation of copies of the relevant media, and promotional and advertising exploitation methods directly related to the site (posters, billboards, particularly all display media, any advertising object not intended for direct sale), as well as for the following media and means: press (newspapers, periodicals), paper editions (catalogs, brochures, leaflets, books, cards, leaflets…), multimedia and electronic editions (internet, intranet, …), commercial documents (institutional brochures, etc…).</p>
                <p>If Dealio suspects that the information and/or comments and/or offers from members infringe the image rights and/or intellectual property rights of third parties, it reserves the right to:</p>
                <ul>
                    <li>Immediately delete, without notice or compensation, any contentious content, information, and/or comments;</li>
                    <li>Suspend or delete the member's profile under the conditions of section 6 below;</li>
                    <li>Restrict or block access to all or part of the services.</li>
                </ul>
                <h4>3.1.2. Intellectual Property Rights of Dealio</h4>
                <p>The site and its elements are protected by intellectual property rights belonging to Dealio. Users are prohibited from engaging in any actions that directly or indirectly infringe on Dealio's intellectual property rights. Thus, users are prohibited from publishing, reproducing, distributing, representing, adapting, disassembling, decompiling, in Vietnam or abroad, all or part of the site's content; using Dealio's technology or know-how for commercial purposes; using Dealio's trademarks and/or images to create or offer services similar to or competing with the site; or registering identical or similar trademarks.</p>
                <p>No transfer of Dealio's intellectual property rights is granted to users. Users are only granted a non-exclusive, free, revocable, and non-transferable right to access and use the site's services and functionalities for the duration of their use of the site's services, as expressly provided in these Terms and Conditions. Any use of data or information from the Dealio site must mention Dealio as the source of information and is subject to obtaining prior formal written authorization from Dealio's administrators.</p>

                <h2>4. User Obligations, Offer Conditions, and Voting</h2>
                <h3>4.1. USER OBLIGATIONS</h3>
                <p>Users agree to comply with all these Terms and Conditions and applicable laws and regulations. Users agree, in particular, to:</p>
                <ul>
                    <li>Not overload the site;</li>
                    <li>Respect the image and reputation of other users and/or Dealio and not harm their interests (moral, commercial, etc.);</li>
                    <li>Not damage the site by not modifying or altering any part of the site or restricting its use by any means, not falsifying any mention or element of the site, not scraping the site's content and database, particularly using automated programs, not distributing the site's content on any other medium without Dealio's prior written authorization, not having behavior likely to interrupt, suspend the site, not infiltrate or attempt to infiltrate the site's systems;</li>
                    <li>Not infiltrate a computer system, not alter content, or commit any offenses related to cybersecurity and computer crimes as defined by Vietnamese laws, specifically the Cybersecurity Law and the Law on Cyberinformation Security.</li>
                    <li>Not distribute or attempt to distribute computer viruses or any other harmful elements through the site;</li>
                    <li>Respect the rights of third parties, particularly by not using, reproducing, copying, distributing, modifying, representing, or distributing texts, photographs, or any other element or works protected by copyright, trademark law, or any other intellectual property right belonging to third parties;</li>
                    <li>Not infiltrate a computer system, not alter content, or commit any offenses punished by articles 323-1 to 323-7 of the Penal Code ("hacking");</li>
                    <li>Not send unsolicited bulk messages ("spamming").</li>
                    <li>Not engage in activities that could cause social instability or threaten national security as outlined in Article 18.1 of the Cybersecurity Law.</li>
                </ul>
                <h3>4.2. MEMBER OBLIGATIONS</h3>
                <p>Members agree to comply with all these Terms and Conditions and applicable laws and regulations and, in particular, to:</p>
                <ul>
                    <li>Not use the site and/or its functionalities for illegal purposes including but not restricted to distributing illegal content, counterfeit goods, illegal products, drugs, pornographic materials, hazardous materials, weapons/ammunitions/explosives, restricted animal products, stolen goods, prescription drugs</li>
                    <li>Not engaging in self-promotion of their services, offers, or other activities, nor those of acquaintances.</li>
                    <li>Not impersonate any third party and/or use or attempt to use another member's profile;</li>
                    <li>Not allow others to use their profile and not transfer their profile to a third party;</li>
                    <li>Not circumvent a sanction by creating a new account on the site;</li>
                    <li>Not have multiple accounts on the site;</li>
                    <li>Publish accurate, reliable and clear information including but not restricted to title, description, link, image, comment</li>
                    <li>Not send a large number of similar comments;</li>
                    <li>Not send a large number of similar private messages;</li>
                    <li>Not send sponsorship proposals and no insinuation or hint of sponsorship in comments, deals, discussions, or unsolicited private messages;</li>
                    <li>Publish comments that adhere to high standards of courtesy, decency, and mutual respect;</li>
                    <li>Not distribute or publish documents or make statements likely to offend others, cause controversy (trolling), contrary to public order or morals, or infringe on the rights or dignity of third parties;</li>
                    <li>Not make defamatory, insulting, denigrating, racist, xenophobic, pornographic statements, or statements generally contrary to morality and decency and/or public order;</li>
                    <li>Not incite the commission of a crime, an offense, or an act of terrorism or make an apology for war crimes or crimes against humanity.</li>
                    <li>Ensure all posted content adheres to Vietnamese laws and regulations as required by Article 19.1 of Decree 53/2022/ND-CP.</li>
                </ul>
                <h3>4.3. OFFER CONDITIONS</h3>
                <p>To respect the community spirit of the site, members agree not to publish offers listed as excluded in the Deal Publishing Rules.</p>
                <h3>4.4. VOTING CONDITIONS FOR OFFERS</h3>
                <p>Members agree, when voting to promote an offer, to do so honestly to not alter the progress or decline of a relevant offer.</p>

                <h2>5. Links, Liability, Guarantee</h2>
                <h3>5.1. HYPERTEXT LINKS</h3>
                <p>The site contains hypertext links, generally those of offers added by members, pointing to resources or websites managed by third parties, which do not belong to or are not controlled by Dealio. The websites to which these hypertext links refer are, unless otherwise stated, independent of the Dealio site.</p>
                <p>Dealio exercises no control over the content or practices of third-party websites and disclaims any responsibility in this regard. Dealio cannot be held responsible for the content of these websites, and the fact that information about these sites appears on the site does not engage its responsibility.</p>
                <p>Users assume sole responsibility and all risks resulting from the consultation and use of these third-party websites. These links do not constitute, in any case, an endorsement or partnership between Dealio and these sites.</p>
                <p>Therefore, Dealio gives no guarantee regarding, in particular:</p>
                <ul>
                    <li>The truthfulness, timeliness, quality, completeness, and exhaustiveness of the content of the offers of products or services indexed on these websites;</li>
                    <li>The relevance and completeness of the indexed websites;</li>
                    <li>The difficulties of access and functioning of these websites.</li>
                </ul>
                <p>Consequently, Dealio cannot be held responsible in the event of a dispute of any nature arising between an indexed website on the site and a user. The operators of the websites referenced by Dealio are solely responsible for compliance with all regulations applicable to the products or services offered to users.</p>
                <p>Without Dealio's prior authorization, it is prohibited to provide on a third-party website a hypertext link pointing to the present site. If you wish to set up a hypertext link to the site, you must contact the site administrator using the contact form available in the "Contact" section on the home page.</p>
                <p>In no case should Dealio's web pages be embedded inside the pages of another site. Everything must be done to clearly indicate to the user that they are on the Dealio site and allow them to navigate freely. In any case, Dealio is not responsible for the links made to its site.</p>

                <h3>5.2. USER AND MEMBER LIABILITY AND GUARANTEE</h3>
                <p>Users are solely responsible for:</p>
                <ul>
                    <li>The use of the site and its services.</li>
                </ul>
                <p>Members are solely responsible for:</p>
                <ul>
                    <li>The offers and/or comments they publish or distribute on the site. In this regard, they guarantee that they have all the rights, licenses, consents, and authorizations necessary for the information they distribute and/or publish on the site. They guarantee that they respect the Vietnam’s Law on Cybersecurity in accordance with Article 19.1 of Decree 53/2022. Finally, they declare and guarantee that the information put online is not confidential and does not violate any contractual obligation.</li>
                </ul>
                <p>Users guarantee Dealio against any damage suffered by it and against any action in liability that would be brought against it based on the violation of these Terms and Conditions and/or any right of a third party. In the case of proven fraud, the site authorizes itself to communicate all necessary information to the competent bodies responsible for suppressing said frauds and offenses.</p>
                <p>Users particularly guarantee Dealio against any claim, legal action, or demand, including, but not limited to, (i) any action concerning the information put online by users, (ii) any use by users of the information on the site, (iii) or any violation of these Terms and Conditions.</p>

                <h2>6. Moderation and Sanctions</h2>
                <h3>6.1. MODERATION OF OFFERS AND SANCTIONS</h3>
                <p>Dealio reserves the discretionary right to moderate, i.e., modify, move, or delete offers and/or content published by members. In case of a member's breach of one of the offer conditions provided in section 4, the member can be warned, but not necessarily, be banned from accessing the site's services, especially in the case of repeated offences.</p>

                <h3>6.2. MODERATION OF COMMENTS AND SANCTIONS</h3>
                <p>Dealio may moderate, i.e., modify or delete, statements made by members and block access to comments and services for members who make statements likely to offend others or infringe on their rights, or who do not comply with these Terms and Conditions, particularly those in section 4 above. The member could receive a prior warning but not necessary.</p>
                <p>In case of a repeat offense but not restricted to it, the offending member may be banned from accessing the site's services. Dealio reserves the discretionary right to refuse certain hypertext links appearing in comments. This deletion right applies to all links on the site.</p>

                <h2>7. Profile Deletion</h2>
                <h3>7.1. PROFILE DELETION</h3>
                <h4>7.1.1. At the Initiative of Members</h4>
                <p>A member can request the deletion of their profile at any time by request to dealio.vn@gmail.com. The profile deletion is permanent, and the personal data linked to it cannot be recovered by members. However, offers, votes for offers, topics, and comments left by the member on the site will be retained and anonymized. The profile will be kept in memory by the site for the legally authorized duration.</p>

                <h4>7.1.2. At the Initiative of Dealio</h4>
                <p>In case of a member's breach of one of their obligations, particularly those in section 4 above, Dealio reserves the discretionary right to suspend or delete the member's account immediately, without notice or compensation. The profile deletion is permanent, and the personal data linked to it cannot be recovered by members. Dealio cannot be held responsible for the consequences of this deletion. However, offers, votes for offers, topics, and comments left by the member on the site will be retained and anonymized. The profile will be kept in memory by the site for the legally authorized duration.</p>

                <h2>8. Dealio's Liability, Reporting</h2>
                <h3>8.1. DEALIO'S LIABILITY</h3>
                <p>The site is merely a community space for sharing various promotional offers. Dealio only provides users with a platform of services, as well as tools and technical means for sharing offers. Dealio acts solely as a content host, providing simple technical storage and availability of spaces in accordance with Vietnamese law and regulations as outlined in the Cybersecurity Law and Decree 53/2022.</p>
                <p>Dealio conducts prior moderation to enhance the overall quality of content published by members. However, any issues not identified during moderation, including violations of the law or these Terms and Conditions, are the sole responsibility of the user. Dealio cannot be held responsible for any such content. Therefore, it cannot be held liable for any illegal or non-compliant content that may be published. Any formatting or layout of these automatically generated elements by the site does not imply Dealio's endorsement or involvement in their posting.</p>
                <p>The choice of offer classification categories, as well as the general organization and operation of the site, is only intended to facilitate its use and should not be interpreted as elements demonstrating the determination of any editorial policy or line. Only users decide on the posted offers. The site's algorithms then automate the classification of offers and their appearance on the site's front page.</p>
                <p>Finally, Dealio cannot be held liable to users for:</p>
                <ul>
                    <li>Any indirect damage they may suffer, including any loss of profit (whether direct or indirect), loss of clientele or business reputation, or any loss of data they may incur;</li>
                    <li>Any loss or damage they may suffer due to:</li>
                    <li>The trust placed in the completeness, accuracy, or existence of any advertising, information, or videos on the site or resulting from any relationship or transaction concluded between the user and any advertiser or partner whose advertising or works appear on the site;</li>
                    <li>Any change Dealio may make to the site in general, or any temporary or permanent deletion of an offer;</li>
                    <li>The deletion, damage, or failure to store the profile or any other communication data stored or transmitted;</li>
                    <li>Their relationship with other members with whom they interact;</li>
                    <li>Accessing or using, or the inability to use or access the site in general;</li>
                    <li>The inability to keep a password or information provided during registration secure and confidential;</li>
                    <li>The inability to delete, or the inability to store or transmit information, comments, or messages;</li>
                    <li>Any information and/or comment on the site;</li>
                    <li>Any behavior of a third party on the site in general, including but not limited to any defamatory, offensive, or illegal conduct of other members or any third party;</li>
                    <li>Any content inserted in messages sent through the site.</li>
                </ul>

                <h3>8.2. REPORTING OFFERS</h3>
                <p>The Dealio site allows its members to report offers and comments posted by other members if they believe that one of the rules of the site's Terms and Conditions, particularly those in section 4 above, has been violated. Reported offers and comments will be analyzed by administrators and/or moderators to determine if the reported comment or offer indeed violates the site's Terms and Conditions. Dealio reserves the right to block, modify, or delete reported comments and offers. Dealio reserves the right to restrict or block access to all or part of the services to members who abuse this reporting system.</p>

                <h2>9. Personal Data and Identifiers</h2>
                <h3>9.1. PERSONAL DATA</h3>
                <h4>9.1.1. Collection and Use of Personal Data</h4>
                <p>Dealio is committed to protecting the personal data of its users. Personal data refers to any information that can identify an individual, such as but not restricted as name, email address, phone number.</p>

                <h4>9.1.2. Data Collected</h4>
                <ul>
                    <li>Registration Data: When users create an account, Dealio collects information such as name, email address, and password.</li>
                    <li>Profile Data: Users may provide additional information for their profiles, including profile pictures and personal descriptions.</li>
                    <li>Usage Data: Dealio collects information on how users interact with the site, including pages viewed, links clicked, and offers posted.</li>
                    <li>Communication Data: This includes information collected when users communicate with Dealio, such as through customer service requests or feedback forms.</li>
                </ul>

                <h4>9.1.3. Purpose of Data Collection</h4>
                <p>Dealio collects and processes personal data for the following purposes:</p>
                <ul>
                    <li>Account Management: To create and manage user accounts.</li>
                    <li>Service Delivery: To provide and improve Dealio's services, including managing offers, votes, and comments.</li>
                    <li>Communication: To send administrative messages and respond to user inquiries.</li>
                    <li>Security: To protect the security of the site and prevent fraudulent activities.</li>
                    <li>Legal Compliance: To comply with legal obligations and respond to requests from government authorities.</li>
                </ul>

                <h4>9.1.4. Data Sharing</h4>
                <p>Dealio may share personal data with third parties in the following circumstances:</p>
                <ul>
                    <li>Service Providers: Dealio may share data with third-party service providers who perform services on its behalf, such as payment processing and data analysis.</li>
                    <li>Legal Requirements: Dealio may disclose personal data if required to do so by law or in response to a legitimate request from a law enforcement authority or government official.</li>
                    <li>Business Transfers: In the event of a merger, acquisition, or sale of all or part of Dealio's assets, personal data may be transferred to the acquiring entity.</li>
                </ul>

                <h4>9.1.5. Data Security</h4>
                <p>Dealio implements appropriate technical and organizational measures to protect personal data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access. However, no internet-based service can be completely secure, and Dealio cannot guarantee the absolute security of personal data. Dealio complies with Vietnamese regulations on personal data protection. Users' data will be processed in accordance with the laws of Vietnam, including data localization requirements as stipulated by Decree 53/2022.</p>

                <h4>9.1.6. Data Retention</h4>
                <p>Dealio retains personal data for as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations. Users can request the deletion of their data by contacting Dealio through the contact form.</p>

                <h4>9.1.7. User Rights</h4>
                <p>Users have the following rights regarding their personal data:</p>
                <ul>
                    <li>Access: Users can request access to their personal data held by Dealio.</li>
                    <li>Rectification: Users can request the correction of inaccurate or incomplete personal data.</li>
                    <li>Deletion: Users can request the deletion of their personal data.</li>
                    <li>Restriction: Users can request the restriction of processing of their personal data.</li>
                    <li>Portability: Users can request a copy of their personal data in a structured, commonly used, and machine-readable format.</li>
                    <li>Objection: Users can object to the processing of their personal data for certain purposes.</li>
                </ul>
                <p>To exercise these rights, users can contact Dealio through the contact form on the site.</p>

                <h4>9.1.8. Changes to this Policy</h4>
                <p>Dealio reserves the right to update this privacy policy at any time. Users will be notified of any significant changes through the site or by email.</p>

                <h2>10. Discussions</h2>
                <h3>10.1. DISCUSSIONS</h3>
                <p>These Terms and Conditions apply to the use and creation of discussion topics and their comments.</p>

                <h2>11. Nullity, Applicable Law, Competent Jurisdiction</h2>
                <h3>11.1. NULLITY</h3>
                <p>If one or more stipulations of these Terms and Conditions are held invalid or declared as such by a law, regulation, or following a final decision of a competent court, the other stipulations will retain their full force and scope.</p>

                <h3>11.2. APPLICABLE LAW, COMPETENT JURISDICTION</h3>
                <p>The use and operation of the site are subject to Vietnamese law and international law. The validity, performance, and interpretation of these Terms and Conditions applicable to the site are also subject to Vietnamese law and international law.</p>
                <p>TO THE EXTENT PERMITTED BY LOCAL LAW APPLICABLE TO THE USER, ANY DISPUTE ARISING FROM THE VALIDITY, INTERPRETATION, AND/OR PERFORMANCE OF THESE TERMS AND CONDITIONS, WHICH THE PARTIES CANNOT RESOLVE AMICABLY, WILL BE SUBMITTED TO THE EXCLUSIVE JURISDICTION OF THE VIETNAMESE COURTS, EVEN IN CASE OF MULTIPLE DEFENDANTS, EMERGENCY PROCEEDINGS, OR THIRD-PARTY NOTICE.</p>
            </div>
        </Container>
    );
}

export default TermsAndConditions_eng;
import React, { createContext, useState, useEffect } from 'react';
import { API_URL } from '../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children, showAlert }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [userName, setUserName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        checkAuthentication();
    }, []);

    const checkAuthentication = async () => {
        try {
            const response = await fetch(API_URL + 'api/username', { credentials: 'include' });
            const data = await response.json();
            if (data.isAuthenticated) {
                login({ name: data.userName, role: data.userRole });
            } else {
                logout();
            }
        } catch (error) {
            console.error('Error checking authentication status:', error);
            logout();
        }
    };

    const login = (userData) => {
        setIsAuthenticated(true);
        setUserRole(userData.role);
        setUserName(userData.name);
    };

    const logout = async () => {
        try {
            await fetch(API_URL + 'auth/logout', { credentials: 'include' });
            setIsAuthenticated(false);
            setUserRole(null);
            setUserName('');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            userRole,
            userName,
            isModalOpen,
            toggleModal,
            login,
            logout,
            checkAuthentication
        }}>
            {children}
        </AuthContext.Provider>
    );
};
//api.js

//const BACKEND_IP = '192.168.96.5'; // Your back-end server's local IP address 192.168.96.4
//const BACKEND_IP = 'localhost'; // Your back-end server's local IP address 192.168.96.4
//const BACKEND_PORT = 5038; // Your back-end server's port

//export const API_URL = `http://${BACKEND_IP}:${BACKEND_PORT}/`;
export const API_URL = process.env.REACT_APP_API_URL;
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;

const apiService = {
    async getAllDeals(limit, skip, categoryName, searchQuery, statusFilter = null) {
        let url = API_URL + `api/GetDeals?limit=${limit}&skip=${skip}`;
    
        /*console.log('getAllDeals parameters:', {
            limit,
            skip,
            categoryName,
            searchQuery,
            statusFilter
        });*/

        if (categoryName) {
            url += `&category=${encodeURIComponent(categoryName)}`;
        }
    
        if (searchQuery) {
            url += `&search=${encodeURIComponent(searchQuery)}`;
        }
    
        // Add status filter to the URL if it's provided
        if (statusFilter) {
            url += `&status=${encodeURIComponent(statusFilter)}`;
        }

        /*console.log('Fetching URL:', url);*/
    
        try {
            const response = await fetch(url, { // Use the constructed URL here
                method: 'GET',
                credentials: 'include', // Include credentials for session cookies
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            //console.log('Fetched data:', data); // Log the fetched data*/
            return data;
        } catch (error) {
            throw error; // propagate the error
        }
    },

    async getAllDealsAdmin(limit, skip, categoryName, searchQuery, pendingValidationOnly = false) {
        let url = API_URL + `api/GetDealsAdmin?limit=${limit}&skip=${skip}`;
    
        if (categoryName) {
            url += `&category=${encodeURIComponent(categoryName)}`;
        }
    
        if (searchQuery) {
            url += `&search=${encodeURIComponent(searchQuery)}`;
        }
    
        if (pendingValidationOnly) {
            url += `&pendingValidationOnly=true`;
        }

        try {
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include', // Include credentials for session cookies
            });
    
            if (!response.ok) {
                if (response.status === 403) {
                    throw new Error('Forbidden access');
                } else {
                    throw new Error('Network response was not ok');
                }
            }
    
            const data = await response.json();
            //console.log("api fetch deals:", data);
            return data;
        } catch (error) {
            throw error; // Propagate the error for further handling
        }
    },

    async deleteDealClick(id){
        return fetch(API_URL + "api/DeleteDeals?id=" + id, {
          method: "DELETE",
        }).then(res => res.json());
    },

    async addDealClick(dealData) {
        const data = new FormData();
    
        for (const key in dealData) {
            if (dealData[key]) { // Only append if there's a value
                data.append(key, dealData[key]);
            }
        }
    
        try {
            const response = await fetch(API_URL + "api/AddDeals", {
                method: "POST",
                body: data,
                credentials: 'include', // if you are handling sessions/cookies
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error("Error adding deal:", error);
            throw error; // propagate the error
        }
    },

    async voteDeal(dealId, value) {
        const voteValue = value === 1 ? 1 : -1; // Set to 1 for thumbUp and -1 for thumbDown
    
        try {
            const response = await fetch(API_URL + "api/voteDeal", {
                method: 'POST',
                credentials: 'include', // Important for sessions
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ dealId, voteValue })
            });
    
            // Check if the response is not ok
            if (!response.ok) {
                // Extract the error message from the server's response
                const errorData = await response.json();
                throw new Error(errorData.message || 'Error occurred while voting');
            }
    
            const data = await response.json();
            return data;
    
        } catch (error) {
            console.error(error);
            alert(error.message); // Display the server's error message in an alert
            throw error; // re-throw the error to handle it later if needed
        }
    },

    async getDealById(id) {
        try {
            const response = await fetch(`${API_URL}api/GetDealById?id=${id}`, {
                method: 'GET',
                credentials: 'include', // Include credentials for session cookies, if needed
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            //console.log('Fetched data dealpage:', response); // Log the fetched data*/
            return await response.json();
        } catch (error) {
            console.error("Error fetching deal:", error);
            throw error;
        }
    },

    async incrementDealViewCounter(dealId) {
        try {
            await fetch(`${API_URL}api/IncrementDealViewCounter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: dealId })
            });
        } catch (error) {
            throw error;
        }
    },

    async submitReport(deal_id, report_type, comment) {
        //console.log(`API service - Report for Deal ID: ${dealId}, Report Type: ${reportType}, Comment: ${comment}`);
    
        try {
            const response = await fetch(`${API_URL}api/FillDealReport`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ deal_id, report_type, comment })
            });
    
            const data = await response.json(); // Get JSON response only once
    
            if (!response.ok) {
                // If response is not OK, throw an error with the message from the server
                throw new Error(data.message || 'Failed to submit report');
            }

        } catch (error) {
            throw error;
        }
    },

    async checkReportUserExists(dealId) {
        try {
            const response = await fetch(`${API_URL}api/CheckReportUserExists?dealId=${dealId}`, {
                method: 'GET',
                credentials: 'include'
            });
    
            if (!response.ok) {
                throw new Error('Failed to check report existence');
            }

            const result = await response.json();
            // Add console log here
            //console.log(`CheckReportUserExists - DealID: ${dealId}, Exists: ${result.exists}`);
    
            return result;
        } catch (error) {
            console.error('Error checking report existence:', error);
            throw error;
        }
    },

    async getAllReportsForDeal(dealId) {
        try {
            const response = await fetch(`${API_URL}api/GetAllReportsForDeal?id=${dealId}`, {
                method: 'GET',
                credentials: 'include', // If you are handling sessions/cookies
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return await response.json();
        } catch (error) {
            console.error("Error fetching reports for deal:", error);
            throw error;
        }
    },

    async updateDealStatus(dealId, dealStatus) {
        try {
            //console.log('Deal id: ', dealId);
            //console.log('Deal status: ', dealStatus);
            const response = await fetch(API_URL + 'api/UpdateDealStatus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ dealId, dealStatus }),
                credentials: 'include', // if you are handling sessions/cookies
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            return await response.json();
        } catch (error) {
            console.error("Error updating deal status:", error);
            throw error;
        }
    }

}

export default apiService;
// CategoryHeader.js

import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './CategoryHeader.css';
import ArrowLeft from '../assets/VerticalMenu_ArrowLeft.svg';
import ArrowRight from '../assets/VerticalMenu_ArrowRight.svg';

// Import the category list
import { CATEGORIES } from '../constants/CategoriesHeaderList';

const CategoryHeader = () => {
    const categoriesContainerRef = useRef(null);
    const transitionDuration = 300; // Match the duration to your CSS variable (in milliseconds)
    const location = useLocation(); // Get the current location

    // Function to parse category from URL query parameters
    const getCurrentCategoryFromQuery = () => {
        const query = new URLSearchParams(location.search);
        return query.get('name') || '';
    };
    
    const currentCategory = getCurrentCategoryFromQuery();

    const handleCategoryClick = (categoryName) => {
        // Encode the categoryName to handle special characters
        const encodedCategoryName = encodeURIComponent(categoryName);
        // Navigate to the route with the selected category using query parameters
        window.location.href = `/category?name=${encodedCategoryName}`;
    };

    const scrollCategories = (direction) => {
        const container = categoriesContainerRef.current;
        if (container) {
            container.style.scrollBehavior = 'smooth';
            const transitionDistance = Math.max(container.offsetWidth - 50, 0);

            if (direction === 'left') {
                container.scrollLeft -= transitionDistance;
            } else {
                container.scrollLeft += transitionDistance;
            }

            setTimeout(() => {
                container.style.scrollBehavior = 'auto';
            }, transitionDuration);
        }
    };

    return (
        <div className="category-header">
            <div className="category-header__container">
                <div className="category-header__arrow-box">
                    <button className="category-header__arrow" onClick={() => scrollCategories('left')}>
                        <img src={ArrowLeft} alt="Left" />
                    </button>
                </div>
                <div className="category-header__categories-container" ref={categoriesContainerRef}>
                    {CATEGORIES.map(category => (
                        <button 
                            key={category.id}
                            className={`category-header__category-button ${category.name === currentCategory ? 'category-header__category-button--selected' : ''}`}
                            onClick={() => handleCategoryClick(category.name)}
                        >
                            <img src={category.icon} alt={category.name} className="category-header__category-icon"/>
                            <span className="category-header__category-text">{category.name}</span>
                        </button>
                    ))}
                </div>
                <div className="category-header__arrow-box">
                    <button className="category-header__arrow" onClick={() => scrollCategories('right')}>
                        <img src={ArrowRight} alt="Right" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CategoryHeader;
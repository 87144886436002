// components/AccountModal.js

import React, { useContext, useEffect, useRef } from 'react';
import './AccountModal.css';
import { AuthContext } from '../contexts/AuthContext';

function AccountModal({ onClose, position }) {
    const { logout } = useContext(AuthContext);
    const modalRef = useRef(null);

    const handleLogout = () => {
        logout();
        onClose();
    };

    useEffect(() => {
        // Add class to body to prevent scrolling
        document.body.classList.add('modal-open');

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            // Remove class from body to allow scrolling again
            document.body.classList.remove('modal-open');
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <>
            <div className="modal-overlay"></div>
            <div
                className="account-modal"
                ref={modalRef}
                style={{ top: position.top, left: position.left - 200 }} // Adjusted left position
            >
                <div className="account-modal__arrow"></div>
                <div className="account-modal__content">
                    <div className="account-modal__header">
                        <h2 className="account-modal__title">Parameters</h2>
                    </div>
                    <div className="account-modal__options">
                        <a href="/terms-and-conditions" target="_blank" className="account-modal__option">Terms and Conditions</a>
                        <button onClick={handleLogout} className="account-modal__option">Disconnect</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AccountModal;
// views/DealPage.js

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import apiService from '../services/api';
import Container from '../components/Container';
import ReportButton from '../components/ReportButton';
import { shareDeal } from '../components/ShareDeal';
import { AuthContext } from '../contexts/AuthContext';
import { HomePageContext } from '../contexts/HomePageContext';
import Carousel from 'react-bootstrap/Carousel';
import './DealPage.css';

import viewsIcon from '../assets/global-icons/views.svg';
import deadlineIcon from '../assets/global-icons/deadline.svg';
import locationIcon from '../assets/global-icons/location.svg';
import thumbUpIcon from '../assets/global-icons/thumb_up.svg';
import thumbDownIcon from '../assets/global-icons/thumb_down.svg';
import thumbUpFullIcon from '../assets/global-icons/thumb_up_full.svg';
import thumbDownFullIcon from '../assets/global-icons/thumb_down_full.svg';
import shareIcon from '../assets/global-icons/share.svg';
import externalLinkIcon from '../assets/global-icons/link.svg';
import shopIcon from '../assets/global-icons/shop.svg';

const copyToClipboard = (text, alertPopup) => {
    navigator.clipboard.writeText(text).then(() => {
        alertPopup('Promo code copied to clipboard!', 'success');
    }).catch(err => {
        console.error('Failed to copy promo code: ', err);
    });
};

const formatPrice = (price) => {
    if (price === null || price === undefined) {
        return '';
    }
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

function DealPage({alertPopup}) {
    const { isAuthenticated, toggleModal } = useContext(AuthContext);
    const { scrollPosition, setScrollPosition } = useContext(HomePageContext);
    const [deal, setDeal] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const voteUpButtonRef = useRef(null); // Reference for vote up button
    const voteDownButtonRef = useRef(null); // Reference for vote down button

    useEffect(() => {
        fetchDeal(id);
        incrementDealViewCounter(id);
    }, [id]);

    const fetchDeal = async (dealId) => {
        try {
            const fetchedDeal = await apiService.getDealById(dealId);
            //console.log("Fetched deals dealpage:", fetchedDeal);
            if (!fetchedDeal) {
                setTimeout(() => navigate('/'), 3000);
            } else {
                setDeal(fetchedDeal);
            }
        } catch (error) {
            console.error("Failed to fetch deal:", error);
            setTimeout(() => navigate('/'), 3000);
        }
    };

    const handleVote = async (dealMongoId, value) => {
        if (!isAuthenticated) {
            toggleModal();
            return;
        }
    
        // Check if the user has already voted
        if (deal.user_vote !== 0) {
            // Add shake animation
            const buttonRef = value === 1 ? voteUpButtonRef : voteDownButtonRef;
            buttonRef.current.classList.add('shake');
            setTimeout(() => {
                buttonRef.current.classList.remove('shake');
            }, 500);
            return;
        }
    
        // Optimistically update the vote count and user vote
        const previousVote = deal.user_vote;
        const newVote = value;
        const voteDifference = newVote - previousVote;
    
        //console.log("Previous vote:", previousVote);
        //console.log("New vote:", newVote);
        //console.log("Vote difference:", voteDifference);
    
        setDeal({
            ...deal,
            vote: deal.vote + voteDifference,
            user_vote: newVote
        });
    
        //console.log("Optimistically updated deal vote count:", deal.vote + voteDifference);
        //console.log("Optimistically updated user vote:", newVote);
    
        try {
            const responseData = await apiService.voteDeal(dealMongoId, value);
            // Update the state with the server response (if needed)
            setDeal({
                ...deal,
                vote: responseData.vote,
                user_vote: responseData.user_vote
            });
    
            //console.log("Server response vote count:", responseData.vote);
            //console.log("Server response user vote:", responseData.user_vote);
        } catch (error) {
            console.error("Failed to vote on deal:", error);
            // Revert to the previous state on error
            setDeal({
                ...deal,
                vote: deal.vote,
                user_vote: previousVote
            });
    
            //console.log("Reverted deal vote count:", deal.vote - voteDifference);
            //console.log("Reverted user vote:", previousVote);
        }
    };

    const handleReportSubmit = () => {
        setDeal((prevDeal) => ({
            ...prevDeal,
            user_report_type: 'reported', // Update the report status appropriately
        }));
    };

    const incrementDealViewCounter = async (dealId) => {
        try {
            await apiService.incrementDealViewCounter(dealId);
        } catch (error) {
            console.error("Failed to increment view counter:", error);
        }
    };

    function formatVoteCount(votes) {
        if (Math.abs(votes) >= 10000) {
            return `${Math.round(votes / 1000)}k`;
        } else if (Math.abs(votes) >= 1000) {
            return `${(votes / 1000).toFixed(1)}k`;
        } else {
            return votes.toString();
        }
    }

    const handleShare = (event) => {
        event.preventDefault();
        event.stopPropagation();
        shareDeal(deal.title, deal._id, alertPopup);
    };

    const handleBackClick = () => {
        setScrollPosition(window.scrollY);
        navigate(-1);
    };

    if (!deal) {
        return <div>Loading...</div>;
    }

    const images = [deal.image1, deal.image2, deal.image3].filter(Boolean);
    const percentageReduction = deal.regular_price && deal.discounted_price ? 
        Math.round(((deal.regular_price - deal.discounted_price) / deal.regular_price) * 100) : null;

        return (
            <div className="deal-page">
                    <Container isMarginlessOnSmallScreen={true}>
                        <div className={deal.status === 'Expired' ? 'deal-page__expired-content' : ''}>
                            {deal.status === 'Expired' && <div className="deal-page__expired-overlay"></div>}
            
                            <div className="deal-page__deal-box">
                                <div className="deal-page__main-content">
                                    <div className="deal-page__image-container">
                                        {deal.status === 'Expired' && <div className="deal-page__expired-header">Expired</div>}
                                        {images.length > 0 ? (
                                            <Carousel indicators={true}>
                                                {images.map((image, index) => (
                                                    <Carousel.Item key={index}>
                                                        <img
                                                            className="d-block w-100"
                                                            src={image}
                                                            alt={`Deal Image ${index + 1}`}
                                                        />
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                        ) : (
                                            <div className="deal-page__no-image-placeholder">No Image Available</div>
                                        )}
                                    </div>
            
                                    <div className="deal-page__summary">
                                        <div className="deal-page__view-name-box">
                                            <div className="deal-page__view-box">
                                                <div className="deal-page__view-item">
                                                    <img src={viewsIcon} alt="Views" className="deal-page__summary-icon" />
                                                    <div className="deal-page__view-item-text">{deal.view_counter} Views</div>
                                                </div>
                                                {deal.end_date && (
                                                    <div className="deal-page__view-item">
                                                        <img src={deadlineIcon} alt="Deadline" className="deal-page__summary-icon" />
                                                        <div className="deal-page__view-item-text">{new Date(deal.end_date).toLocaleDateString()}</div>
                                                    </div>
                                                )}
                                                <div className="deal-page__view-item">
                                                    <img src={locationIcon} alt="Location" className="deal-page__summary-icon" />
                                                    <div className="deal-page__view-item-text">{deal.location}</div>
                                                </div>
                                                {deal.shop && (
                                                    <div className="deal-page__view-item">
                                                        <img src={shopIcon} alt="Shop" className="deal-page__summary-icon" />
                                                        <div className="deal-page__view-item-text">{deal.shop}</div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="deal-page__name-box">
                                                <div className="deal-page__title">{deal.title}</div>
                                                {deal.promo_code && (
                                                    <div className="deal-page__promo-code" onClick={() => copyToClipboard(deal.promo_code, alertPopup)}>
                                                        Promo Code: <span className="deal-page__promo-code-value">{deal.promo_code}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="deal-page__price-box">
                                            {deal.regular_price !== null && deal.regular_price !== undefined && (
                                                <div className="deal-page__regular-price">
                                                    <span className="deal-page__currency-symbol-regular-price">đ</span>
                                                    {formatPrice(deal.regular_price)}
                                                </div>
                                            )}
                                            {(deal.discounted_price !== null && deal.discounted_price !== undefined) || percentageReduction ? (
                                                <div className="deal-page__current-price-container">
                                                    {deal.discounted_price !== null && deal.discounted_price !== undefined && (
                                                        <div className="deal-page__current-price">
                                                            <span className="deal-page__currency-symbol-current-price">đ</span>
                                                            {formatPrice(deal.discounted_price)}
                                                        </div>
                                                    )}
                                                    {percentageReduction !== null && percentageReduction >= 1 && (
                                                        <div className="deal-page__reduction">
                                                            -{percentageReduction}% GIAM
                                                        </div>
                                                    )}
                                                </div>
                                            ) : null}
                                        </div>
            
                                        <div className="deal-page__interaction-box">
                                            <div className="deal-page__vote-container">
                                                <div className="deal-page__vote-section">
                                                    <button
                                                        ref={voteUpButtonRef}
                                                        className={`deal-page__vote-button ${deal.user_vote !== 0 ? 'disabled' : ''}`}  // Add 'disabled' class if user_vote is not 0
                                                        onClick={() => handleVote(id, 1)}
                                                        aria-label="Vote Up">
                                                        <img src={deal.user_vote === 1 ? thumbUpFullIcon : thumbUpIcon} alt="Thumb Up" className="deal-page__vote-icon" />
                                                    </button>
                                                </div>
                                                <div className="deal-page__vote-section">
                                                    <span className={deal.vote < 0 ? 'deal-page__vote-negative-count' : ''}>
                                                        {formatVoteCount(deal.vote)}
                                                    </span>
                                                </div>
                                                <div className="deal-page__vote-section">
                                                    <button
                                                        ref={voteDownButtonRef}
                                                        className={`deal-page__vote-button ${deal.user_vote !== 0 ? 'disabled' : ''}`}  // Add 'disabled' class if user_vote is not 0
                                                        onClick={() => handleVote(id, -1)}
                                                        aria-label="Vote Down">
                                                        <img src={deal.user_vote === -1 ? thumbDownFullIcon : thumbDownIcon} alt="Thumb Down" className="deal-page__vote-icon" />
                                                    </button>
                                                </div>
                                            </div>
                                            {deal.link ?
                                                <button onClick={() => window.open(deal.link, '_blank')} className="deal-page__deal-link-button">
                                                    <img src={externalLinkIcon} alt="External Link" />
                                                    Xem Deal
                                                </button>
                                                :
                                                <div className="deal-page__deal-link-placeholder"></div>
                                            }
                                            <div className="deal-page__share-box" onClick={handleShare}>
                                                <img src={shareIcon} alt="Share" className="deal-page__share-icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="deal-page__description">
                                    <b>About this deal:<br /><br />
                                        <div dangerouslySetInnerHTML={{ __html: deal.description }}></div>
                                    </b><br />
                                    <div className="deal-page__description-buttons">
                                        <ReportButton dealId={deal._id} userReportType={deal.user_report_type} alertPopup={alertPopup} onReportSubmit={handleReportSubmit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            );
    }
    
    export default DealPage;
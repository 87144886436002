import React, { useState, useEffect, useContext, useRef } from 'react';
import apiService from '../services/api';
import Container from '../components/Container';
import './NewDeal.css';
import ImageDropZone from '../components/ImageDropZone';
import DescriptionBox from '../components/DescriptionBox';
import LoadingSpinner from '../components/LoadingSpinner';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { PROVINCES } from '../constants/ProvinceList.js';
import { CATEGORIES } from '../constants/CategoriesHeaderList';

import linkIcon from '../assets/global-icons/link-color.svg';
import titleIcon from '../assets/global-icons/title-color.svg';
import discountedPriceIcon from '../assets/global-icons/current-price-color.svg';
import regularPriceIcon from '../assets/global-icons/regular-price-color.svg';
import promoCodeIcon from '../assets/global-icons/promo-code-color.svg';
import shopIcon from '../assets/global-icons/shop-color.svg';
import locationIcon from '../assets/global-icons/location-color.svg';
import startDateIcon from '../assets/global-icons/start-date.svg';
import endDateIcon from '../assets/global-icons/end-date.svg';

export default function NewDeal({ alertPopup }) {
    const { isAuthenticated, authLoading, toggleModal } = useContext(AuthContext);
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();

    const MAX_PRICE = 9999999999;

    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState(CATEGORIES);
    const [focusedField, setFocusedField] = useState('');
    const [uploadedFileNames, setUploadedFileNames] = useState([]);
    const [images, setImages] = useState([null, null, null]);
    const [isNationalDeal, setIsNationalDeal] = useState(true);
    const [locationDropdown, setLocationDropdown] = useState('');
    const [noLinkAvailable, setNoLinkAvailable] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [urlLengthError, setUrlLengthError] = useState(false);
    const [titleLengthError, setTitleLengthError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [promoCodeError, setPromoCodeError] = useState(false);
    const [attemptedNextWithoutImage, setAttemptedNextWithoutImage] = useState(false);
    const [descriptionLengthError, setDescriptionLengthError] = useState(false);
    const todayDate = new Date().toISOString().split('T')[0];
    const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0];
    const [categoryError, setCategoryError] = useState(false);
    const [priceComparisonError, setPriceComparisonError] = useState(false);
    const [dateComparisonError, setDateComparisonError] = useState(false);
    const [CurrentPriceError, setCurrentPriceError] = useState(false);
    const [RegularPriceError, setRegularPriceError] = useState(false);
    const [discountedPriceErrorClass, setCurrentPriceErrorClass] = useState('');
    const [regularPriceErrorClass, setRegularPriceErrorClass] = useState('');
    const [shopError, setShopError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const timeoutRef = useRef(null);
    const [dealData, setDealData] = useState({
        title: '',
        link: '',
        discounted_price: '',
        regular_price: '',
        promo_code: '',
        location: 'National',
        shop: '',
        description: '',
        start_date: '',
        start_time: '',
        end_date: '',
        end_time: '',
        image1: null,
        image2: null,
        image3: null
    });

    const [modalShown, setModalShown] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (!authLoading && !isAuthenticated && !modalShown) {
            toggleModal();
            setModalShown(true);
        }
    }, [authLoading, isAuthenticated, modalShown, toggleModal]);

    useEffect(() => {
        setIsAnimating(true); // Trigger animation on mount
    }, [currentStep]);

    // Function to handle field focus
    const handleFieldFocus = (fieldName) => {
        setFocusedField(fieldName);
    };

    // Function to handle field blur
    const handleFieldBlur = () => {
        setFocusedField('');
    };

    const handleChange = (e) => {
        let { name, value } = e.target;

        // Handle URL changes
        if (name === "link") {
            // Check if URL length exceeds 2000 characters
            if (value.length > 2000) {
                setUrlLengthError(true);
            } else {
                setUrlLengthError(false);
            }

            // Validate the URL format
            const isValidUrl = isUrlValid(value) || value === '';
            setUrlError(!isValidUrl);
        }

        // Check title length (must be between 4 and 140 characters)
        if (name === "title") {
            const isTitleLengthValid = value.trim().length >= 4 && value.trim().length <= 140;
            setTitleLengthError(!isTitleLengthValid);
        }

        // Validate discounted_price and regular_price (must be non-negative numbers) and < MAX_PRICE
        if (name === "discounted_price" || name === "regular_price") {
            if (parseFloat(value) < 0 || isNaN(parseFloat(value)) || parseFloat(value) > MAX_PRICE) {
                if (name === "discounted_price") {
                    setCurrentPriceError(true);
                    clearErrorsAfterDelay(() => setCurrentPriceError(false), setCurrentPriceErrorClass);
                } else {
                    setRegularPriceError(true);
                    clearErrorsAfterDelay(() => setRegularPriceError(false), setRegularPriceErrorClass);
                }
                if (parseFloat(value) < 0 || isNaN(parseFloat(value))) {
                    value = '';
                    e.target.value = ''; // Update the input field value to ''
                }
            } else {
                if (name === "discounted_price") {
                    setCurrentPriceError(false);
                } else {
                    setRegularPriceError(false);
                }
            }
            value = value === '' ? '' : parseFloat(value).toString();
        }

        // Compare discounted_price and regular_price (discounted_price must be <= regular_price)
        if (name === "discounted_price" || name === "regular_price") {
            const discountedPriceValue = name === "discounted_price" ? value : dealData.discounted_price;
            const regularPriceValue = name === "regular_price" ? value : dealData.regular_price;
            const isPriceComparisonValid = !discountedPriceValue || !regularPriceValue || parseFloat(discountedPriceValue) < parseFloat(regularPriceValue);

            setPriceComparisonError(!isPriceComparisonValid);
        }

        // Check shop name length (must be <= 60 characters)
        if (name === "shop") {
            if (value.length > 60) {
                setShopError(true);
            } else {
                setShopError(false);
            }
        }

        // Check promo code length (must be <= 140 characters)
        if (name === "promo_code") {
            if (value && value.length > 140) {
                setPromoCodeError(true);
            } else {
                setPromoCodeError(false);
            }
        }

        const updatedDealData = {
            ...dealData,
            [name]: value
        };

        // Validate date comparisons (start_date must be <= end_date)
        if (name === "start_date" || name === "end_date" || name === "start_time" || name === "end_time") {
            const startDateTime = `${updatedDealData.start_date}T${updatedDealData.start_time || '00:00'}`;
            const endDateTime = `${updatedDealData.end_date}T${updatedDealData.end_time || '23:59'}`;
            const start = new Date(startDateTime);
            const end = new Date(endDateTime);
            const isValidDateComparison = !updatedDealData.end_date || start <= end;
            setDateComparisonError(!isValidDateComparison);
        }

        setDealData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Prevent form submission on Enter Key - redirect to wrong url - and send to next step
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            //handleNextStep();
        }
    };

    const handleDealCategorySelection = (categoryName) => {
        setSelectedCategory(categoryName);
        setCategoryError(false);
    };

    const handleNextStep = () => {
        if (!isAuthenticated) {
            toggleModal();
            return;
        }

        setIsAnimating(false); // Reset animation state before changing step

        setTimeout(() => {
            if (currentStep === 1) {
                const isLinkValid = noLinkAvailable || (dealData.link.length <= 2000 && isUrlValid(dealData.link));

                setUrlError(!isLinkValid && !noLinkAvailable);
                setUrlLengthError(dealData.link.length > 2000);

                if (!isLinkValid) {
                    return;
                }
            } else if (currentStep === 2) {
                const isTitleLengthValid = dealData.title?.trim().length >= 4 && dealData.title?.trim().length <= 140;
                setTitleLengthError(!isTitleLengthValid);
            
                const isPromoCodeValid = !dealData.promo_code || dealData.promo_code.length <= 140;
                setPromoCodeError(!isPromoCodeValid);
            
                const isLocationValid = isNationalDeal || (locationDropdown !== '');
                setLocationError(!isLocationValid);
            
                const isCurrentPriceValid = dealData.discounted_price === '' || (!isNaN(parseFloat(dealData.discounted_price)) && parseFloat(dealData.discounted_price) >= 0 && parseFloat(dealData.discounted_price) <= MAX_PRICE);
                setCurrentPriceError(!isCurrentPriceValid);
    
                const isRegularPriceValid = dealData.regular_price === '' || (!isNaN(parseFloat(dealData.regular_price)) && parseFloat(dealData.regular_price) >= 0 && parseFloat(dealData.regular_price) <= MAX_PRICE);
                setRegularPriceError(!isRegularPriceValid);
    
                const isPriceComparisonValid = (dealData.discounted_price === '' || dealData.regular_price === '') || parseFloat(dealData.discounted_price) <= parseFloat(dealData.regular_price);
                setPriceComparisonError(!isPriceComparisonValid);
            
                if (!isTitleLengthValid || !isPromoCodeValid || !isLocationValid || !isCurrentPriceValid || !isRegularPriceValid || !isPriceComparisonValid) {
                    return;
                }
            } else if (currentStep === 3) {
                if (images[0] === null) {
                    setAttemptedNextWithoutImage(true);
                    return;
                }
            } else if (currentStep === 4) {
                if (descriptionLengthError) {
                    return;
                }
            } else if (currentStep === 5) {
                if (dateComparisonError) {
                    return;
                }

                if (!selectedCategory) {
                    setCategoryError(true);
                    return;
                }
            }

            setCurrentStep(currentStep + 1);
            setIsAnimating(true); // Trigger animation for the new step
        }, 100); // Adjust timeout as needed to allow for animation reset
    };

    const handleBackStep = () => {
        setIsAnimating(false); // Reset animation state before changing step

        setTimeout(() => {
            setCurrentStep(currentStep - 1);
            setIsAnimating(true); // Trigger animation for the new step
        }, 100); // Adjust timeout as needed to allow for animation reset
    };

    const handleAddDealClick = async () => {
        setIsLoading(true);
        const form = document.getElementById("dealForm");
        if (!form.checkValidity()) {
            form.reportValidity();
            setIsLoading(false);
            return;
        }

        const fullStartDate = dealData.start_time ? `${dealData.start_date}T${dealData.start_time}` : dealData.start_date;
        const fullEndDate = dealData.end_time ? `${dealData.end_date}T${dealData.end_time}` : dealData.end_date;

        const submittedDealData = {
            ...dealData,
            start_date: fullStartDate,
            end_date: fullEndDate,
            category: selectedCategory,
            image1: images[0],
            image2: images[1],
            image3: images[2]
        };

        try {
            const result = await apiService.addDealClick(submittedDealData);
            setIsLoading(false);
            alertPopup(`Your deal is currently in validation and should be available shortly 🥳`, 'success');
            //navigate(`/deal/${encodeURIComponent(result.dealTitle)}/${result.dealId}`);
            navigate(`/`);
        } catch (error) {
            setIsLoading(false);
            alertPopup('Error adding deal. Please try again.', 'error');
            console.error(error);
        }
    };

    const handleImageDrop = (file, index) => {
        setImages(prevImages => {
            let updatedImages = [...prevImages];

            if (file) {
                updatedImages[index] = file;
                if (!uploadedFileNames.includes(file.name)) {
                    setUploadedFileNames(prevNames => [...prevNames, file.name]);
                }
            } else {
                const existingFileName = updatedImages[index]?.name;
                updatedImages[index] = null;
                if (existingFileName) {
                    setUploadedFileNames(prevNames => prevNames.filter(name => name !== existingFileName));
                }
            }

            updatedImages = updatedImages.filter(image => image != null);
            while (updatedImages.length < 3) {
                updatedImages.push(null);
            }

            return updatedImages;
        });
    };

    const handleNationalDealChange = (e) => {
        setIsNationalDeal(e.target.checked);
        if (e.target.checked) {
            setDealData(prevData => ({
                ...prevData,
                location: "National"
            }));
            setLocationDropdown('');
            setLocationError(false);
        } else {
            setDealData(prevData => ({
                ...prevData,
                location: locationDropdown
            }));
        }
    };

    const handleLocationDropdownChange = (e) => {
        const selectedLocation = e.target.value;
        setLocationDropdown(selectedLocation);
        setDealData(prevData => ({
            ...prevData,
            location: selectedLocation
        }));

        if (selectedLocation !== "") {
            setLocationError(false);
        }
    };

    const handleNoLinkCheckboxChange = (e) => {
        setNoLinkAvailable(e.target.checked);
        if (e.target.checked) {
            setDealData(prevData => ({ ...prevData, link: '' }));
            setUrlError(false);
            setUrlLengthError(false);
        }
    };

    const isUrlValid = (url) => {
        const urlRegex = /^(https?:\/\/)[^\s$.?#].[^\s]*$/;
        return urlRegex.test(url);
    };

    const handleDescriptionChange = (content, delta, source, editor) => {
        //const textLength = editor.getLength();
        const textLength = dealData.description.length;

        if (textLength > 64000) {
            setDescriptionLengthError(true);
        } else {
            setDescriptionLengthError(false);
        }

        setDealData(prevData => ({
            ...prevData,
            description: content
        }));
    };

    const clearErrorsAfterDelay = (clearFunction, setErrorClassFunction) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setErrorClassFunction('fade-out');
            setTimeout(() => {
                clearFunction();
                setErrorClassFunction(''); // Reset the class after fade out
            }, 1000); // Wait for the fade-out transition to complete
        }, 3000);
    };

    const renderCurrentStep = () => {
        switch (currentStep) {
            case 1:
                return renderStep1();
            case 2:
                return renderStep2();
            case 3:
                return renderStep3();
            case 4:
                return renderStep4();
            case 5:
                return renderStep5();
            case 6:
                return renderStep6();

            default:
                return <div>Unknown step</div>;
        }
    };

    const renderStep1 = () => {
        const inputClassName = (urlError || urlLengthError) ? 'new-deal__input--error' : '';
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">Share a good deal with thousands of people</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>Please enter the link where we can enjoy the deal or get more information.</p><br />
                        <div className={`new-deal__field-container ${noLinkAvailable ? 'new-deal__field-container--disabled' : ''}`}>
                            <div className="new-deal__input-container">
                                <img src={linkIcon} className="new-deal__icon" alt="Link Icon" />
                                <div className="new-deal__sub-input-container">
                                    <span className="new-deal__sub-title">Link</span>
                                    <input
                                        name="link"
                                        value={dealData.link}
                                        onChange={handleChange}
                                        className={inputClassName}
                                        autoComplete="off"
                                        placeholder="https://www.example.com/amazingdeal"
                                        disabled={noLinkAvailable}
                                        autoCapitalize="off"
                                    />
                                    {urlLengthError && (
                                        <div className="new-deal__error-message-box">
                                            URL is too long. Please keep it under 2000 characters.
                                        </div>
                                    )}
                                    <div className="new-deal__national-deal-checkbox new-deal__national-deal-checkbox--render1">
                                        <label htmlFor="noLinkCheckbox"><center>I don't have a link for this deal</center></label>
                                        <input
                                            type="checkbox"
                                            id="noLinkCheckbox"
                                            checked={noLinkAvailable}
                                            onChange={handleNoLinkCheckboxChange}
                                            className="new-deal__national-deal-input"
                                        />
                                    </div>
                                    {urlError && (
                                        <div className="new-deal__error-message-box">
                                            Please enter a valid URL (e.g., https://www.example.com) or no link for deal
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleNextStep}>Next</button>
                </div>
            </>
        );
    };
    
    const renderStep2 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">Let's add some useful information</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={titleIcon} className="new-deal__icon" alt="Title Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div>
                                        <span className="new-deal__sub-title">Title</span> (Mandatory)<br />
                                        <input
                                            name="title"
                                            value={dealData.title}
                                            onChange={handleChange}
                                            className={titleLengthError ? 'new-deal__input--error' : ''}
                                            onFocus={() => handleFieldFocus('title')}
                                            onBlur={handleFieldBlur}
                                            required
                                            autoComplete="off"
                                            placeholder="Enter the title of the deal"
                                        />
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'title' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'title' && (
                                            <p>
                                                <strong>Make your title stand out</strong><br />
                                                Give the type of product, the brand, the model, the color
                                                (Ex: Shoes woman adidas Stan Smith white, size 36-40)
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {titleLengthError && (
                                <div className="new-deal__error-message-box">
                                    Title should be between 4 and 140 characters.
                                </div>
                            )}
                        </div>
                        <div className="new-deal__sub-field-container">
                            <div className="new-deal__field-container">
                                <div className="new-deal__input-container">
                                    <img src={discountedPriceIcon} className="new-deal__icon" alt="Current Price Icon" />
                                    <div className="new-deal__sub-input-container">
                                        <div>
                                            <label htmlFor="discounted_price"><span className="new-deal__sub-title">Current price</span></label><br />
                                            <input
                                                id="discounted_price"
                                                name="discounted_price"
                                                value={dealData.discounted_price}
                                                onChange={handleChange}
                                                onInput={handleChange}
                                                className={(priceComparisonError || CurrentPriceError) ? 'new-deal__input--error' : ''}
                                                onFocus={() => handleFieldFocus('discounted_price')}
                                                onBlur={handleFieldBlur}
                                                autoComplete="off"
                                                placeholder="950000"
                                            />
                                        </div>
                                        <div className={`new-deal__info-box ${focusedField === 'discounted_price' ? 'new-deal__info-box--visible' : ''}`}>
                                            {focusedField === 'discounted_price' && (
                                                <p><strong>Give the Price</strong><br />
                                                    It's the final price after deducting all discounts</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {priceComparisonError && (
                                    <div className="new-deal__error-message-box">
                                        Current price should be lower than regular price
                                    </div>
                                )}
                                {CurrentPriceError && (
                                    <div className={`new-deal__error-message-box ${discountedPriceErrorClass}`}>
                                        Price should be empty or a number more than 0 and less than d10Bn
                                    </div>
                                )}
                            </div>
                            <div className="new-deal__field-container">
                                <div className="new-deal__input-container">
                                    <img src={regularPriceIcon} className="new-deal__icon" alt="Regular Price Icon" />
                                    <div className="new-deal__sub-input-container">
                                        <div>
                                            <label htmlFor="regular_price"><span className="new-deal__sub-title">Regular price</span></label><br />
                                            <input
                                                id="regular_price"
                                                name="regular_price"
                                                //type="number"
                                                value={dealData.regular_price}
                                                onChange={handleChange}
                                                onInput={handleChange}
                                                className={RegularPriceError ? 'new-deal__input--error' : ''}
                                                onFocus={() => handleFieldFocus('regular_price')}
                                                onBlur={handleFieldBlur}
                                                autoComplete="off"
                                                placeholder="1600000"
                                            />
                                        </div>
                                        <div className={`new-deal__info-box ${focusedField === 'regular_price' ? 'new-deal__info-box--visible' : ''}`}>
                                            {focusedField === 'regular_price' && (
                                                <p>
                                                    <strong>Generally observed price</strong><br />
                                                    This is the lowest price you can find for the same product elsewhere by comparing prices (not the recommended selling price)
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {RegularPriceError && (
                                    <div className={`new-deal__error-message-box ${regularPriceErrorClass}`}>
                                        Price should be empty or a number more than 0 and less than d10Bn
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={promoCodeIcon} className="new-deal__icon" alt="Promo Code Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div>
                                        <label htmlFor="promo_code"><span className="new-deal__sub-title">Promo Code</span></label><br />
                                        <input
                                            id="promo_code"
                                            type="text"
                                            name="promo_code"
                                            value={dealData.promo_code}
                                            onChange={handleChange}
                                            className={promoCodeError ? 'new-deal__input--error' : ''}
                                            onFocus={() => handleFieldFocus('promo_code')}
                                            onBlur={handleFieldBlur}
                                            autoComplete="off"
                                            placeholder="Enter promo code"
                                        />
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'promo_code' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'promo_code' && (
                                            <p>
                                                Enter your promo code if applicable<br />
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {promoCodeError && (
                                <div className="new-deal__error-message-box">
                                    Promo code should be 140 characters or less.
                                </div>
                            )}
                        </div>
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={shopIcon} className="new-deal__icon" alt="Shop Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div>
                                        <label htmlFor="shop"><span className="new-deal__sub-title">Shop</span></label><br />
                                        <input
                                            id="shop"
                                            type="text"
                                            name="shop"
                                            value={dealData.shop}
                                            onChange={handleChange}
                                            className={shopError ? 'new-deal__input--error' : ''}
                                            onFocus={() => handleFieldFocus('shop')}
                                            onBlur={handleFieldBlur}
                                            autoComplete="off"
                                            placeholder="Enter shop name"
                                        />
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'shop' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'shop' && (
                                            <p>Enter the name of the shop where the deal is available</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {shopError && (
                                <div className="new-deal__error-message-box">
                                    Shop name should be 60 characters or less.
                                </div>
                            )}
                        </div>
                        <div className="new-deal__field-container">
                            <div className="new-deal__input-container">
                                <img src={locationIcon} className="new-deal__icon" alt="Location Icon" />
                                <div className="new-deal__sub-input-container">
                                    <div className="new-deal__national-location-container">
                                        <div className="new-deal__national-deal-checkbox">
                                            <label htmlFor="nationalDeal"><span className="new-deal__sub-title new-deal__sub-title--centered">National Deal</span></label>
                                            <input
                                                type="checkbox"
                                                id="nationalDeal"
                                                checked={isNationalDeal}
                                                onChange={handleNationalDealChange}
                                                className="new-deal__national-deal-input"
                                            />
                                        </div>
                                        <div className={`new-deal__location-container ${isNationalDeal ? 'new-deal__location-container--disabled' : ''}`}>
                                            <label htmlFor="locationDropdown"><span className="new-deal__sub-title">Province</span></label>
                                            <select
                                                id="locationDropdown"
                                                value={locationDropdown}
                                                onChange={handleLocationDropdownChange}
                                                disabled={isNationalDeal}
                                                className={locationError ? 'new-deal__input--error' : ''}
                                            >
                                                <option value="">Select Location</option>
                                                {PROVINCES.map((province, index) => (
                                                    <option key={index} value={province}>
                                                        {province}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className={`new-deal__info-box ${focusedField === 'location' ? 'new-deal__info-box--visible' : ''}`}>
                                        {focusedField === 'location' && (
                                            <p>Choose the location for the deal.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {locationError && (
                                <div className="new-deal__error-message-box">
                                    Location is required.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>Back</button>
                    <button type="button" onClick={handleNextStep}>Next</button>
                </div>
            </>
        );
    };

    // Function to render step 3
    const renderStep3 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">Show your deal with images</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>People need at least an image to know about the deal. You can add up to 3 for more details</p><br />
                        <div className="new-deal__field-container">
                            {descriptionLengthError && (
                                <div className="new-deal__error-message-box">
                                    Description should be 64000 characters or less.
                                </div>
                            )}
                            {images.map((image, index) => {
                                const key = image ? `image-${index}-${image.lastModified}` : `empty-${index}`;
                                return (
                                    index === 0 || images[index - 1] ? (
                                        <ImageDropZone 
                                            key={key}
                                            onImageDrop={file => handleImageDrop(file, index)} 
                                            previewUrl={image ? URL.createObjectURL(image) : null} 
                                            existingFileNames={uploadedFileNames} // Pass the file names list
                                            requireImageError={attemptedNextWithoutImage && images.every(img => img === null)} //Pass the error when user want to reach next step without at least one image
                                        />
                                    ) : null
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>Back</button>
                    <button type="button" onClick={handleNextStep}>Next</button>
                </div>
            </>
        );
    };
    
    const renderStep4 = () => {

        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">Why is this deal worth it?</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>Explain why this deal is worth sharing! Provide details about the product or service, how to obtain the price if necessary, include links to any relevant information or reviews... and don't forget to mention the city and available stock if it's an in-store offer</p><br />
                        <div className="new-deal__field-container">
                            {descriptionLengthError && (
                                <div className="new-deal__error-message-box">
                                    Description should be less than 64000 characters.
                                </div>
                            )}
                            <div className="new-deal__description-box-container">
                                <DescriptionBox
                                    value={dealData.description}
                                    onChange={handleDescriptionChange}
                                />
                            </div>
                            <br /><br />
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>Back</button>
                    <button type="button" onClick={handleNextStep}>Next</button>
                </div>
            </>
        );
    };
    
    const renderStep5 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">Give some last important details</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <div className="new-deal__field-container">
                            <div className="new-deal__sub-field-container">
                                <div className="new-deal__field-container">
                                    <div className="new-deal__input-container">
                                        <img src={startDateIcon} className="new-deal__icon" alt="Start Date Icon" />
                                        <div className="new-deal__sub-input-container">
                                            <label htmlFor="start_date"><strong>Start Date</strong></label>
                                            <input
                                                id="start_date"
                                                name="start_date"
                                                type="date"
                                                value={dealData.start_date}
                                                onChange={handleChange}
                                                required
                                                min={todayDate}
                                                max={maxDate}
                                            />
                                            {dealData.start_date && (
                                                <>
                                                    <label htmlFor="start_time"><strong>Start Time</strong></label>
                                                    <input
                                                        id="start_time"
                                                        name="start_time"
                                                        type="time"
                                                        value={dealData.start_time}
                                                        onChange={handleChange}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="new-deal__field-container">
                                    <div className="new-deal__input-container">
                                        <img src={endDateIcon} className="new-deal__icon" alt="End Date Icon" />
                                        <div className="new-deal__sub-input-container">
                                            <label htmlFor="end_date"><strong>End Date</strong></label>
                                            <input
                                                id="end_date"
                                                name="end_date"
                                                type="date"
                                                value={dealData.end_date}
                                                onChange={handleChange}
                                                min={dealData.start_date || todayDate}
                                                max={maxDate}
                                                className={dateComparisonError ? 'new-deal__input--error' : ''}
                                            />
                                            {dealData.end_date && (
                                                <>
                                                    <label htmlFor="end_time"><strong>End Time</strong></label>
                                                    <input
                                                        id="end_time"
                                                        name="end_time"
                                                        type="time"
                                                        value={dealData.end_time}
                                                        onChange={handleChange}
                                                        className={dateComparisonError ? 'new-deal__input--error' : ''}
                                                    />
                                                </>
                                            )}
                                            {dateComparisonError && (
                                                <div className="new-deal__error-message-box">
                                                    The start date cannot be earlier than the end date.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="new-deal__field-container">
                            {categoryError && (
                                <div className="new-deal__error-message-box">
                                    Please select a category to proceed.
                                </div>
                            )}
                            <label><strong>Category</strong></label>
                            <div className="new-deal__category-buttons">
                                {CATEGORIES.map(category => (
                                    <button
                                        key={category.id}
                                        type="button"
                                        className={`new-deal__category-button ${selectedCategory === category.name ? 'selected' : ''}`}
                                        onClick={() => handleDealCategorySelection(category.name)}
                                    >
                                        <img src={category.icon} alt={`${category.name} icon`} className="new-deal__category-icon" />
                                        {category.name}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>Back</button>
                    <button type="button" onClick={handleNextStep}>Next</button>
                </div>
            </>
        );
    };
    
    const renderStep6 = () => {
        return (
            <>
                <div className={`new-deal__title-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <h2 className="new-deal__title">Confirm Deal Details</h2>
                </div>
                <div className={`new-deal__content-container ${isAnimating ? 'new-deal__animated' : ''}`}>
                    <div className="new-deal__form-container">
                        <p>Please review the deal information and click 'Submit' to add the deal.</p>
                        <br />
                        <div className="new-deal__deal-summary">
                            <div className="new-deal__content-container-sumup">
                                <strong>Link:</strong><br />
                                {dealData.link || <span className="new-deal__empty-data">Empty</span>}
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>Title:</strong><br />
                                {dealData.title || <span className="new-deal__empty-data">Empty</span>}<br />
                                <strong>Current Price:</strong> 
                                {dealData.discounted_price || <span className="new-deal__empty-data">Empty</span>}<br />
                                <strong>Regular Price:</strong> 
                                {dealData.regular_price || <span className="new-deal__empty-data">Empty</span>}<br />
                                <strong>Promo Code:</strong><br />
                                {dealData.promo_code || <span className="new-deal__empty-data">Empty</span>}<br />
                                <strong>Shop:</strong><br />
                                {dealData.shop || <span className="new-deal__empty-data">Empty</span>}<br />
                                <strong>Location:</strong><br />
                                {dealData.location || <span className="new-deal__empty-data">Empty</span>}
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>Start Date:</strong><br />
                                {dealData.start_date ? (
                                    `${dealData.start_date}${dealData.start_time ? ` at ${dealData.start_time}` : ''}`
                                ) : (
                                    <span className="new-deal__empty-data">Empty</span>
                                )}<br />
                                <strong>End Date:</strong><br />
                                {dealData.end_date ? (
                                    `${dealData.end_date}${dealData.end_time ? ` at ${dealData.end_time}` : ''}`
                                ) : (
                                    <span className="new-deal__empty-data">Empty</span>
                                )}<br />
                                <strong>Category:</strong> 
                                {selectedCategory || <span className="new-deal__empty-data">Empty</span>}
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>Images:</strong><br />
                                <div className="new-deal__image-container">
                                    {images.map((image, index) => (
                                        <div key={index} className="new-deal__image-box">
                                            {image ? (
                                                <img src={URL.createObjectURL(image)} alt={`Deal Image ${index + 1}`} />
                                            ) : (
                                                <span className="new-deal__empty-data">Empty</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="new-deal__content-container-sumup">
                                <strong>Description:</strong> 
                                {dealData.description ? (
                                    <div dangerouslySetInnerHTML={{ __html: dealData.description }}></div>
                                ) : (
                                    <span className="new-deal__empty-data">Empty</span>
                                )}
                            </div>
                        </div>
                        <div className="new-deal__rules-box">
                            <p><strong>Rules:</strong></p>
                            <ol>
                                <li>No Illegal Activities or Content (weapons, drugs, pornographic materials...)</li>
                                <li>No Counterfeit Goods</li>
                                <li>No Self or Relatives Promotion</li>
                                <li>Accurate and respectful information</li>
                                <li>Respect copyright regulations</li>
                                <li>Comply with Vietnamese regulations</li>
                            </ol>
                            <p>By submitting a deal I accept the terms and conditions stated here: <a href="/terms-and-conditions" target="_blank">Terms and conditions</a></p>
                        </div>
                    </div>
                </div>
                <div className="new-deal__button-container">
                    <button type="button" onClick={handleBackStep}>Back</button>
                    <button type="button" onClick={handleAddDealClick}>Submit</button>
                </div>
            </>
        );
    };

    return (
        <div className="new-deal">
            {isLoading && <LoadingSpinner />}
            <Container isMarginlessOnSmallScreen={true}>
                <div className="new-deal__boxes">
                    <form id="dealForm" onKeyDown={handleKeyDown}>
                        {renderCurrentStep()}
                    </form>
                </div>
            </Container>
        </div>
    );
}